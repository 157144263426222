export const environment = {
    production: false,
    appName: 'Logame Manager',
    home: '/painel',
    // api: 'http://127.0.0.1:8000/api',
    // api: 'https://xsa-manager-api.officecom.app/api',
    // url: 'https://xsa-manager.officecom.app/',
    api: 'https://dev-logame-manager-api.officecom.app/api',
    url: 'https://dev-logame-manager.officecom.app/',

    passwordRules: {
        minlength: 8,
        minLowercaseCharacterRule: 1,
        minUppercaseCharacterRule: 1,
        minDigitCharacterRule: 1,
        minSpecialCharacterRule: 1
    },
};